.lots {
  & .actions {
    @apply relative flex w-full flex-col pb-2 sm:flex-row;

    & .dropdowns {
      @apply flex flex-col gap-2 sm:flex-1 lg:flex-auto lg:flex-row;
    }

    & .externalLinks {
      @apply flex sm:max-w-xs sm:flex-1 sm:flex-col sm:justify-end sm:gap-2 sm:pl-5 lg:ml-auto lg:flex-none lg:pl-0 xl:flex-row 2xl:max-w-none;

      & :global(.button) {
        @apply mx-0 mt-4 w-full sm:mt-0 lg:mt-0 2xl:w-auto;
      }

      & .allLots {
        @apply hidden sm:inline-flex lg:hidden 2xl:inline-flex;
      }
    }
  }

  & p {
    @apply text-charcoal;
  }

  & .panel {
    @apply hidden;

    &.active {
      @apply block;
    }
  }

  & .searchResults {
    & h4 {
      @apply mb-0 p-0 font-secondary text-base font-normal text-gray-500;
    }

    & div:not(:first-child) {
      @apply mt-2 border-t pt-4;
    }
  }

  & .noResults {
    @apply pt-2 text-sm text-charcoal;

    & strong {
      @apply text-black underline;
    }
  }

  /* Listings */
  & .cards {
    @apply mb-0 flex flex-col gap-6 p-0;
    @apply xs:grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5;
  }

  & .list {
    @apply mb-0 grid w-full grid-cols-2 p-0;
    @apply sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5;
  }

  & .backButton {
    @apply mb-3 flex items-center text-sm text-gray-500;

    &:hover,
    &:focus {
      @apply text-red-hover underline;
    }
  }

  /* Lots Dropdown Component */
  & .dropdownWrapper {
    @apply static;

    & .button {
      @apply relative h-full min-h-[52px] w-full rounded-sm bg-white px-5 text-left font-secondary text-base font-bold uppercase text-charcoal transition-all sm:max-w-xs lg:min-h-0 lg:w-52;

      &::after {
        @apply absolute right-3 top-1/2 mt-1 h-0 w-0 -translate-y-1/2 transform border-transparent border-t-gray-500 content-[''];

        border-width: 6px;
      }

      &:hover,
      &:focus {
        @apply text-black;
      }
    }

    & .buttonActive {
      @apply shadow-inset-red;

      &::after {
        @apply -mt-0.5 border-b-gray-500 border-t-transparent;
      }
    }

    & .dropdown {
      @apply left-0 mt-2 block w-full scale-99 transform rounded-md bg-white p-8 opacity-0 shadow-md transition-all duration-200;

      &::-webkit-scrollbar {
        @apply w-2.5;
      }

      &::-webkit-scrollbar-track {
        @apply bg-gray-100;
      }

      &::-webkit-scrollbar-thumb {
        @apply bg-red-base;
      }

      &:global(#menu-auction-dropdown) {
        @apply top-[24%] sm:top-1/3 lg:top-full;
      }

      &:global(#menu-make-dropdown) {
        @apply top-2/4 sm:top-2/3 lg:top-full;
      }

      &:global(#menu-category-dropdown) {
        @apply top-[72%] sm:top-full lg:top-full;

        &:has(.noResults) {
          @apply h-fit;
        }
      }

      &:global(#menu-auction-dropdown),
      &:global(#menu-category-dropdown) {
        @apply overflow-y-auto;
        @apply h-[384px] md:h-auto;
      }

      &.dropdownActive {
        @apply mt-0 scale-100 opacity-100;
      }
    }
  }
}
