.register {
  @apply lg:flex;

  p {
    @apply mb-10 text-base;
  }

  .panelLeft {
    @apply mx-auto max-w-sm lg:mx-0 lg:w-1/2 lg:max-w-none lg:border-r-2 lg:border-r-gray-600 lg:pr-8 xl:w-2/3;

    :global(.bidderRegistration) {
      @apply items-start gap-1 xl:flex-row;

      :global(select) {
        @apply h-[56px] xl:mr-1 xl:w-2/3;
      }

      :global(.button) {
        @apply w-full self-start font-secondary xl:w-1/3 xl:min-w-0;
      }
    }

    .link {
      @apply lg:hidden;
    }
  }

  .panelRight {
    @apply mx-auto hidden text-center lg:block lg:w-1/2 lg:pl-7 xl:w-1/3;

    .intro {
      @apply xl:flex xl:flex-col;

      .button {
        @apply mb-2 w-full font-secondary tracking-normal xl:mx-auto xl:w-auto xl:min-w-[280px];

        & + .button {
          @apply mb-0;
        }
      }
    }
  }

  .link {
    @apply mt-4 block text-center;
  }
}
